import styled from 'styled-components'

export const OrgContainer = styled.div`
  min-height: 5rem;
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: 4rem 3fr;
  margin-bottom: ${({ theme }) => theme.spacings.medium};

  ${({ theme }) => theme.breakpoints.md} {
    grid-template-columns: 2rem 3fr;
    grid-gap: 2rem;
  }
`

export const OrgSvg = styled.svg`
  height: ${({ theme }) => theme.fontSizes.h3};
  fill: ${({ theme }) => theme.colors.color3};
`

export const OrgDiv = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.h5};
`

export const OrgText = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.h5};
`
