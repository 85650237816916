import React from 'react'
import SEO from '../../SEO'

import { HomeContainer, IntroWrapper, Hi, Me, HomeLink } from './HomeElements'

const Home = () => {
  return (
    <>
      <SEO title="Home" url={window.location.href} />
      <HomeContainer>
        <IntroWrapper>
          <Hi>Hi there!</Hi>
          <br />
          Welcome to my site <Me>I'm codingChewie</Me>, a web developer in Franklin, TN. Feel free
          to take a look around my site to check out <HomeLink to="/github">my code</HomeLink> and
          see what <HomeLink to="/about">I can do</HomeLink> or to just{' '}
          <HomeLink to="/contact">say hello</HomeLink>.
        </IntroWrapper>
      </HomeContainer>
    </>
  )
}

export default Home
