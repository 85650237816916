import React from 'react'
import SEO from '../../SEO'

// Icons
import { FaGithub, FaLinkedin, FaTwitter } from 'react-icons/fa'
// import { HiOutlineMail } from 'react-icons/hi'

// Styles
import { PageHead, PageContainer } from '../../../theme/GlobalStyles'
import { ContactText, Social, SocialItem, SocialText, FYI } from './ContactElements'

const Contact = () => {
  return (
    <>
      <SEO title="Contact" />
      <PageHead>Contact</PageHead>
      <PageContainer>
        <ContactText>
          Have any questions or simply want to talk about your next project?
        </ContactText>

        <FYI>
          Please allow up to 72 hours for me to get back to you. If you need to get ahold of me
          sooner please contact me through LinkedIn.
        </FYI>
        <Social>
          {/* <SocialEmail href="mailto:someone@yoursite.com?subject=Mail from Our Site">
            <HiOutlineMail /> <SocialText>Email</SocialText>
          </SocialEmail> */}

          <SocialItem href="https://github.com/codingChewie" target="_blank" rel="noopener">
            <FaGithub /> <SocialText>Github</SocialText>
          </SocialItem>

          <SocialItem
            href="https://www.linkedin.com/in/matthewtimothy/"
            target="_blank"
            rel="noopener"
          >
            <FaLinkedin /> <SocialText>LinkedIn</SocialText>
          </SocialItem>

          <SocialItem href="https://twitter.com/codingchewie" target="_blank" rel="noopener">
            <FaTwitter /> <SocialText>Twitter</SocialText>
          </SocialItem>
        </Social>
      </PageContainer>
    </>
  )
}

export default Contact
