import styled, { createGlobalStyle } from 'styled-components'

// Fonts
import NotoSans from './fonts/NotoSansJP-Regular.otf'
import NotoSansThin from './fonts/NotoSansJP-Thin.otf'
import NotoSansBold from './fonts/NotoSansJP-Bold.otf'

// CSS
import reset from './reset.css'
import scrollbar from './scrollbar.css'

export const GlobalStyles = createGlobalStyle`
  ${reset}
  ${scrollbar}
  @font-face {
    font-family: ${({ theme }) => theme.fonts.main};
    src: url(${NotoSans}) format('opentype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: ${({ theme }) => theme.fonts.main};
    src: url(${NotoSansThin}) format('opentype');
    font-weight: 200;
  }

  @font-face {
    font-family: ${({ theme }) => theme.fonts.main};
    src: url(${NotoSansBold}) format('opentype');
    font-weight: bold;
  }

body {
  font-family: ${({ theme }) => theme.fonts.main};
  line-height: 1.75;
  min-height: -webkit-fill-available;
}
html {
  height: -webkit-fill-available;
}

p {margin-bottom: ${({ theme }) => theme.spacings.small};}

h1, h2, h3, h4, h5 {
  margin: ${({ theme }) => theme.spacings.large} 0 1.38rem;
  line-height: 1.3;
}

h1 {
  margin-top: 0;
  font-size: ${({ theme }) => theme.fontSizes.h1};
}
h2 {font-size: ${({ theme }) => theme.fontSizes.h2};}
h3 {font-size: ${({ theme }) => theme.fontSizes.h3};}
h4 {font-size: ${({ theme }) => theme.fontSizes.h4};}
h5 {font-size: ${({ theme }) => theme.fontSizes.h5};}
small, .text_small {font-size: ${({ theme }) => theme.fontSizes.small};}

a,
a:visited {
  color: ${({ theme }) => theme.colors.color4};
}

a:hover {
  color: ${({ theme }) => theme.colors.color2};
  transition: ${({ theme }) => theme.animation.transition};
}
`

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 1280px;
  margin-right: auto;
  margin-left: auto;
  padding-right: ${({ theme }) => theme.spacings.large};
  padding-left: ${({ theme }) => theme.spacings.large};

  ${({ theme }) => theme.breakpoints.md} {
    padding-right: ${({ theme }) => theme.spacings.medium};
    padding-left: ${({ theme }) => theme.spacings.medium};
  }
`

export const PageContainer = styled.div`
  width: 100%;
  height: 100%;

  max-width: 960px;
  margin-right: auto;
  margin-left: auto;
  padding-right: ${({ theme }) => theme.spacings.large};
  padding-left: ${({ theme }) => theme.spacings.large};

  ${({ theme }) => theme.breakpoints.md} {
    padding-right: ${({ theme }) => theme.spacings.small};
    padding-left: ${({ theme }) => theme.spacings.small};
  }
`

export const Button = styled.button`
  border-radius: 4px;
  background: ${({ primary }) =>
    primary ? ({ theme }) => theme.colors.color3 : ({ theme }) => theme.colors.color2};
  white-space: nowrap;
  padding: ${({ big }) => (big ? '12px 64px' : '8px 24px')};
  color: ${({ theme }) => theme.colors.color6};
  font-size: ${({ fontBig }) =>
    fontBig ? ({ theme }) => theme.fontSizes.h5 : ({ theme }) => theme.fontSizes.h5};
  cursor: pointer;

  &:hover {
    transition: all 0.3s ease-out;
    background: ${({ theme }) => theme.colors.color2};
    background-color: ${({ primary }) =>
      primary ? `${({ theme }) => theme.colors.color2}` : `${({ theme }) => theme.colors.color2}`};
  }

  ${({ theme }) => theme.breakpoints.md} {
    width: 100%;
  }
`

export const PageHead = styled.h1`
  color: ${({ theme }) => theme.colors.color3};
  margin: ${({ theme }) => theme.spacings.xLarge} auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  letter-spacing: 0.03em;

  ${({ theme }) => theme.breakpoints.md} {
    margin: ${({ theme }) => theme.spacings.medium} auto;
  }

  &:after {
    content: '';
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0.2rem auto 0 auto;
    width: 4rem;
    border-bottom: 4px solid ${({ theme }) => theme.colors.color1};
  }
`

export const PageSubHead = styled.h2`
  color: ${({ theme }) => theme.colors.color4};
  letter-spacing: 0.03em;

  &:after {
    content: '';
    display: block;
    margin-top: 1rem;
    width: 5rem;
    border-bottom: 4px solid ${({ theme }) => theme.colors.color2};
  }
`
