import React from 'react'

import { PointGrid, PointTotal, PointItem, PointName } from './TreePointElements'

const TreePoints = ({ points }) => {
  return (
    <PointGrid>
      {
        // eslint-disable-next-line
        Object.keys(points).map((point, key) => {
          if (points[point] > 0) {
            return point === 'total' ? (
              <PointTotal key={key}>
                {points[point]}
                <PointName>total points</PointName>
              </PointTotal>
            ) : (
              <PointItem key={key}>
                {points[point]}
                <PointName>{point}</PointName>
              </PointItem>
            )
          }
        })
      }
    </PointGrid>
  )
}

export default TreePoints
