import React from 'react'
import { Helmet } from 'react-helmet'

const SEO = ({ title }) => {
  const description = 'codingChewie portfolio site'
  const image = 'b'
  const url = '/meta-image.png'

  return (
    <>
      <Helmet>
        {/* General tags */}
        <title>{title} | codingChewie</title>
        <meta name="description" content={description} />
        <meta name="image" content={image} />

        {/* OpenGraph tags */}
        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="codingChewie" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
      </Helmet>
    </>
  )
}

export default SEO
