import { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Link } from 'react-router-dom'

import { IconContext } from 'react-icons/lib'

// Global Style
import { Container } from '../../theme/GlobalStyles'

export const Nav = styled.nav`
  background: ${({ theme }) => theme.colors.color6};
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes.h5};
  position: sticky;
  top: 0;
  z-index: 999;
`

export const NavbarContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  height: 80px;
  ${Container}
`

export const MobileIcon = styled.div`
  display: none;

  ${({ theme }) => theme.breakpoints.md} {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 45%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;

  ${({ theme }) => theme.breakpoints.md} {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: ${({ click }) => (click ? 0 : '-100%')};
    opacity: 1;
    transition: ${({ theme }) => theme.animation.ease};
    background: ${({ theme }) => theme.colors.color6};
  }
`

export const NavItem = styled.li`
  height: 80px;
  position: relative;

  @media screen and (min-width: 960px) {
    border-bottom: 2px solid transparent;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 4px;
      top: 0;
      left: 0;
      background-color: ${({ theme }) => theme.colors.color2};
      visibility: hidden;
      transform: scaleX(0);
      transition: ${({ theme }) => theme.animation.transition};
    }

    &:hover:before {
      visibility: visible;
      transform: scaleX(1);
    }
  }

  ${({ theme }) => theme.breakpoints.md} {
    width: 100%;
    &:hover {
      border: none;
    }
  }
`

export const NavItemBtn = styled.li`
  ${({ theme }) => theme.breakpoints.md} {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
  }
`

export const NavLinks = styled(Link)`
  color: ${({ theme }) => theme.colors.color4};
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  height: 100%;

  &:visited {
    color: ${({ theme }) => theme.colors.color4};
  }

  @media screen and (min-width: 960px) {
    &:hover {
      color: ${({ theme }) => theme.colors.white};
      transition: ${({ theme }) => theme.animation.transition};
    }
  }

  ${({ theme }) => theme.breakpoints.md} {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;

    &:hover {
      color: ${({ theme }) => theme.colors.color2};
      transition: ${({ theme }) => theme.animation.ease};
    }
  }
`

export const NavBtnLink = styled(Link)`
  @media screen and (min-width: 960px) {
    margin-left: 0.5rem;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  height: 100%;
  width: 80%;
`

export const NavProvider = ({ children }) => {
  const themeContext = useContext(ThemeContext)
  return (
    <IconContext.Provider value={{ color: themeContext.colors.color4 }}>
      {children}
    </IconContext.Provider>
  )
}
