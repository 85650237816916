import styled from 'styled-components'

export const PointGrid = styled.div`
  margin-top: ${({ theme }) => theme.spacings.medium};
  display: grid;
  grid-template-columns: auto auto auto auto;

  ${({ theme }) => theme.breakpoints.md} {
    grid-template-columns: auto auto;
  }

  ${({ theme }) => theme.breakpoints.sm} {
    grid-template-columns: auto;
  }
`

export const PointTotal = styled.div`
  grid-column: 1/-1;
  color: ${({ theme }) => theme.colors.color2};
  font-size: ${({ theme }) => theme.fontSizes.h4};
`

export const PointItem = styled.div`
  color: orange;
  color: ${({ theme }) => theme.colors.color3};
`

export const PointName = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-size: 75%;
  letter-spacing: 0.02em;
  margin-left: 0.5rem;
  font-variant: small-caps;
`
