import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import styled from 'styled-components'

// import DarkMode from './components/DarkMode'

// Components
import Home from './components/pages/Home/'
import About from './components/pages/About/'
import Github from './components/pages/Github'
import Contact from './components/pages/Contact/'
import Footer from './components/Footer'
import NavBar from './components/NavBar'
import NotFound from './components/pages/NotFound'

const ColumnWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

const Content = styled.div`
  flex: 1;
`

const App = () => {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <ColumnWrapper>
        <NavBar />
        <Content>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/about" exact component={About} />
            <Route path="/github" exact component={Github} />
            <Route path="/contact" exact component={Contact} />
            <Route component={NotFound} />
          </Switch>
        </Content>
        <Footer />
      </ColumnWrapper>
    </Router>
  )
}

export default App
