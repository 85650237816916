import React, { useEffect, useState } from 'react'
import axios from 'axios'

// Styled Components
import { TreeContainer, TreeText } from './TreeHouseElements'

// Components
import TreeIcon from './TreeIcon'
import Loader from '../Loader'
import TreePoints from './TreePoints'
import TreeBadges from './TreeBadges'

const TreeHouse = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  const fetchTrees = async () => {
    try {
      const { data } = await axios.get(
        `https://teamtreehouse.com/${process.env.REACT_APP_TREEHOUSE}.json`,
      )

      setData(data)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setData('error')
    }
  }

  useEffect(() => fetchTrees(), [])

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <TreeContainer>
          <TreeIcon />
          <TreeText>
            When I'm not writing code I'm learning about code, especially JavaScript. After
            completing several courses from LinkedIn I decided to try learning with Team Treehouse
            and below are courses I've completed.
            <TreePoints points={data.points} />
            <TreeBadges badges={data.badges} />
          </TreeText>
        </TreeContainer>
      )}
    </>
  )
}

export default TreeHouse
