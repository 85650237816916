import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { Container } from '../../../theme/GlobalStyles'

export const HomeContainer = styled(Container)`
  height: 80vh;
  display: flex;
  align-items: center;
  padding-right: ${({ theme }) => theme.spacings.xxLarge};
  padding-left: ${({ theme }) => theme.spacings.xxLarge};

  ${({ theme }) => theme.breakpoints.md} {
    margin-top: ${({ theme }) => theme.spacings.xLarge};
    margin-bottom: ${({ theme }) => theme.spacings.xLarge};
    padding-right: ${({ theme }) => theme.spacings.medium};
    padding-left: ${({ theme }) => theme.spacings.medium};
  }
`

export const IntroWrapper = styled.h1`
  display: flex;
  display: inline-block;
  color: ${({ theme }) => theme.colors.color4};
  line-height: 4.5rem;

  ${({ theme }) => theme.breakpoints.sm} {
    margin-top: ${({ theme }) => theme.spacings.medium};
    font-size: 2.488rem;
    line-height: 3.5rem;
  }
`

export const Hi = styled.span`
  font-size: 75%;
  color: ${({ theme }) => theme.colors.color3};
`

export const Me = styled.span`
  color: ${({ theme }) => theme.colors.color3};
`

export const HomeLink = styled(Link)`
  position: relative;

  color: ${({ theme }) => theme.colors.color4};
  text-decoration: underline;
  text-decoration-style: dashed;
  text-underline-offset: ${({ theme }) => theme.spacings.small};
  text-decoration-color: ${({ theme }) => theme.colors.color5};

  &:hover {
    color: ${({ theme }) => theme.colors.color2};
    transition: ${({ theme }) => theme.animation.transition};
    text-decoration-color: ${({ theme }) => theme.colors.color2};
  }

  ${({ theme }) => theme.breakpoints.sm} {
    text-underline-offset: ${({ theme }) => theme.spacings.xSmall};
  }
`
