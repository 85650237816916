import styled from 'styled-components'
import { BsFolder } from 'react-icons/bs'

export const RepoContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacings.medium};
  display: grid;
  grid-gap: 1rem;
  grid-auto-rows: 1fr;
  grid-template-columns: auto auto auto;

  ${({ theme }) => theme.breakpoints.md} {
    grid-template-columns: auto auto;
  }

  ${({ theme }) => theme.breakpoints.sm} {
    grid-template-columns: auto;
  }
`

export const Repo = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.color1};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 16px;
  padding: 30px;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.08);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
    border: 1px solid ${({ theme }) => theme.colors.color5};
  }
`

export const RepoLink = styled.a`
  display: grid;
`

export const RepoIcon = styled(BsFolder)`
  color: ${({ theme }) => theme.colors.color2};
  font-size: 2rem;
  margin-bottom: 1rem;
`

export const RepoTitle = styled.div`
  display: flex;
  font-size: 1rem;
  line-height: 1.4;
  margin-bottom: 0.5rem;
`

export const RepoDesc = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.color4};
  line-height: 1.3;
  font-size: 0.85rem;
  margin-bottom: 0.5rem;
`

export const RepoLanguage = styled.small`
  display: flex;
  color: ${({ theme }) => theme.colors.color3};
  align-self: flex-end;
  margin-top: auto;
`
