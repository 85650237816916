const theme = {
  fonts: {
    main: 'Noto Sans JP, sans-serif',
    code: 'Roboto Mono, monospace',
  },
  colors: {
    color1: '#373f51', // Charcoal
    color2: '#e07a5f', // Terra Cotta
    color3: '#edae49', // Sun Ray
    color4: '#ebcfb2', // Peach Puff
    color5: '#584b55', // Dark Liver
    color6: `#262c38`, //Gun Metal
    white: `#ffffff`,
    // 878b96
  },
  fontSizes: {
    h1: `3.052rem`,
    h2: `2.441rem`,
    h3: `1.953rem`,
    h4: `1.563rem`,
    h5: `1.25rem`,
    p: `1rem`,
    small: `0.8rem`,
    xSmall: `0.64rem`,
    xxSmall: `0.512rem`,
  },
  spacings: {
    xxSmall: '.25rem', // 4px
    xSmall: '.5rem', // 8px
    small: '1rem', // 16px
    medium: '2rem', // 32px
    large: '3rem', // 48px
    xLarge: '4rem', // 64px
    xxLarge: '6rem', // 96px
  },
  breakpoints: {
    sm: '@media only screen and (max-width: 600px)',
    md: '@media only screen and (max-width: 960px)',
    lg: '@media only screen and (max-width: 1280px)',
    xl: '@media only screen and (max-width: 1920px)',
  },
  animation: {
    transition: `all 0.4s ease-in-out`,
    quick: `all 0.2s ease-in-out`,
    ease: `all 0.4s ease`,
    button: 'box-shadow 0.3s ease',
    link: 'color 0.2s ease',
  },
  shadows: {
    shadow1: '0 5px 20px rgba(30,30,31,0.05)',
  },
}

export default theme
