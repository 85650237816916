import styled from 'styled-components'

export const ContactText = styled.h4`
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  margin: 0.5rem auto;
`

export const FYI = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.color4};
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0.5rem auto;
  max-width: 600px;
  line-height: 1.5;

  @media only screen and (max-width: 600px) {
    max-width: 300px;
  }
`

export const Social = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  margin: 4rem auto;
  flex-direction: column;
`

export const SocialEmail = styled.a`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.color4};
  font-size: ${({ theme }) => theme.fontSizes.h3};
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0.5rem auto;

  &:hover {
    color: ${({ theme }) => theme.colors.color2};
    transition: ${({ theme }) => theme.animation.transition};
    transform: scale(1.08);
  }

  &:visited {
    color: ${({ theme }) => theme.colors.color4};
  }
`

export const SocialItem = styled.a`
  color: ${({ theme }) => theme.colors.color4};
  font-size: ${({ theme }) => theme.fontSizes.h3};
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0.5rem auto;

  &:hover {
    color: ${({ theme }) => theme.colors.color2};
    transition: ${({ theme }) => theme.animation.transition};
    transform: scale(1.08);
  }

  &:visited {
    color: ${({ theme }) => theme.colors.color4};
  }
`

export const SocialText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.h4};
  font-weight: 200;
  margin-left: 1rem;
`
