import React from 'react'

import { FooterWrapper, FooterContainer } from './FooterElements'

const Footer = () => {
  return (
    <>
      <FooterWrapper>
        <FooterContainer>Site designed and coded by codingChewie</FooterContainer>
      </FooterWrapper>
    </>
  )
}

export default Footer
