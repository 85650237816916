import React from 'react'
import SEO from '../SEO'

// Components
import Org from '../Org'

// Styles
import { PageHead, PageContainer, PageSubHead } from '../../theme/GlobalStyles'

const Github = () => {
  const url = window.location.pathname

  return (
    <>
      <SEO title="Github" url={url} />
      <PageHead>Github</PageHead>
      <PageContainer>
        <PageSubHead>Personal</PageSubHead>
        <Org org="users/codingchewie">
          <svg viewBox={'0 0 500 500'}>
            <path d="M395.47 250.77c-24.9-27.23-58.1-33.62-92.57-37.27a90.64 90.64 0 01-23-5.4c-10.2-4-12.2-11.7-6-20.8 14.2-21 35.2-30.4 59.5-33.7 3.21-.47 6.49-.63 9.92-.87a1.26 1.26 0 011.08 2c-3.56 4.48-6.82 8.57-10.3 13a1.27 1.27 0 00.36 1.88c8 4.52 16.3 7.06 17.44 16.47 1.1 8.9-5.12 13.5-11.33 18.68a1.27 1.27 0 00.38 2.16c23.59 8.43 47.69 15.66 55.11 43.53.12.46-.28.66-.59.32zM251.3 212.3c12.8.5 24.9 4.7 33.6 16.1 9.2 12 6 20.5-8.5 24.4a59.86 59.86 0 00-12.7 5.6c-8.5 4.6-16.8 4.7-25.4.3-4.6-2.3-9.2-4.8-14.1-6.2-13.2-4-16.6-12.7-8.3-23.9 8.4-11.5 20.4-16.1 35.4-16.3z" />
            <path d="M330.73 137.71a1.26 1.26 0 01-1-2.29c26.07-15.63 54.07-22.66 85-13.72a22.89 22.89 0 0111.5 8.6c17 25.7 25.7 54.6 31.3 84.5 8.43 44.83 8.2 89.95 3.52 135.17a1.27 1.27 0 01-1.26 1.13 1.27 1.27 0 01-1.26-1.26c0-19.18.88-38.36-.2-57.44-2-35.1-7.4-69.8-21.5-102.4a146.92 146.92 0 00-13.1-24c-19.2-28.3-46.8-39.1-80.2-31.7-4.34.96-8.53 2.17-12.8 3.41z" />
            <path d="M271.26 161.81c7.15-51.95 29.3-95.49 71.42-127.94a.61.61 0 01.85.85C326.8 56 314.3 79.57 304.94 105.17a1.26 1.26 0 002.16 1.24c6.77-8.2 13.18-16.33 20.4-23.71 15-15.4 33.1-26.1 53.5-33 5.2-1.8 8.8-.9 12.5 3.4a151.23 151.23 0 0127 45.3.61.61 0 01-.68.81c-63.49-11-108.19 18.58-146.37 63.59a1.26 1.26 0 01-2.19-.99zM280.6 426.8c-8.77 11.49-10.92 23.05-10.66 35.67a1.16 1.16 0 01-2.15.65c-13-20.92-8.33-49.72 11-67.82 3.1-2.9 6.7-5.5 10.1-8.1 12.16-9.41 21.53-20.94 27.45-35.16a1.17 1.17 0 011.25-.7 1.17 1.17 0 011 1.15V370a1.17 1.17 0 002 .81c14.06-14.33 17.89-31.23 19.78-49.11a1.27 1.27 0 012-.89c19.19 13.86 25.16 36.76 13.81 53.26a64.93 64.93 0 01-8.7 9.5c-16.8 16.1-23.8 35.6-21 58.7.08.51.14 1 .21 1.59a1.17 1.17 0 01-1.91 1c-9.08-7.95-11.84-17.38-13-43.87a1.17 1.17 0 00-2.17-.54c-8.71 15-12.06 30-10.29 45.59 1.71 14.86 5.61 29.4 8.64 43.11a1.17 1.17 0 01-2 1c-14.41-16.94-28.3-35.63-25.31-63.41z" />
            <path d="M378.44 363.13c-3.59-11.58-6-22.3-10.42-33.48-16.7-42.1-49-63.6-94.2-63.4-10.5 0-17.4 5.9-17.7 14.8-.3 9.4 6.5 15.7 17.4 15.8 16.1.2 32.1.7 48.2.2 12.1-.4 21.5 4.1 28.8 13.3 3.8 4.8 7.7 9.9 10.4 15.4 6 12.7 16.59 37.74 16.59 37.74a.5.5 0 00.63.29.51.51 0 00.3-.65zM415.74 249.37a.54.54 0 00-.42-.23.56.56 0 00-.54.41.36.36 0 000 .11c11.54 48 13 84.68 5.59 99.89a1.25 1.25 0 01-2.23.08c-7.91-14.16-15.63-29.49-25-43.73-8.23-12.52-19.25-22.52-32-30.41a1.26 1.26 0 00-1.56 2c32.63 33.51 50.3 73.53 50.55 121.66a1.26 1.26 0 002.22.81c27.92-32.53 36.01-94.52 3.39-150.59zM319.18 23.39a.85.85 0 00-.45-.75c-14.92-9-44.26-15.63-57.47-13a1.25 1.25 0 00-1 1.19q-1.5 50.94-2.83 102.12a1.26 1.26 0 002.45.43c11.44-34.84 29.2-65.52 59-89.28a.84.84 0 00.3-.71zM105.1 250.47c7.42-27.87 31.52-35.1 55.11-43.53a1.27 1.27 0 00.38-2.16c-6.21-5.18-12.43-9.78-11.33-18.68 1.14-9.41 9.42-11.95 17.44-16.47a1.27 1.27 0 00.36-1.88c-3.47-4.41-6.74-8.5-10.3-13a1.26 1.26 0 011.08-2c3.44.24 6.71.4 9.92.87 24.3 3.3 45.3 12.7 59.5 33.7 6.2 9.1 4.2 16.8-6 20.8a90.64 90.64 0 01-23 5.4c-34.47 3.65-67.66 10-92.56 37.27-.32.32-.7.12-.6-.32zM290 336.19a1.27 1.27 0 011.26 1.37c-1.36 14.06-8.83 23.68-19.59 31.14-7.4 5.2-13.6 11.3-16.8 20-.65 1.83-1.26 3.66-2.05 6a1.26 1.26 0 01-2.41-.08c-3.28-12.3-10.29-20.24-19.74-26.81-10.27-7.17-17.44-16.69-18.33-30.37a1.26 1.26 0 011.26-1.33c25.76-.01 50.81-.01 76.4.08z" />
            <path d="M157.66 134.3c-33.4-7.4-61 3.4-80.2 31.7a146.92 146.92 0 00-13.1 24c-14.1 32.6-19.5 67.3-21.5 102.4-1.07 19.08-.24 38.26-.2 57.44a1.26 1.26 0 01-1.26 1.26 1.27 1.27 0 01-1.26-1.1c-4.68-45.22-4.91-90.34 3.52-135.17C49.26 184.9 58 156 75 130.3a22.89 22.89 0 0111.5-8.6c30.91-8.94 58.91-1.91 85 13.72a1.26 1.26 0 01-1 2.29 207.6 207.6 0 00-12.84-3.41z" />
            <path d="M227.69 162.8c-38.18-45-82.88-74.59-146.37-63.59a.61.61 0 01-.68-.81 151.44 151.44 0 0127-45.3c3.7-4.3 7.3-5.2 12.5-3.4 20.4 6.9 38.5 17.6 53.5 33 7.22 7.38 13.64 15.51 20.4 23.71a1.26 1.26 0 002.16-1.24c-9.35-25.6-21.86-49.13-38.58-70.45a.6.6 0 01.84-.85c42.13 32.45 64.27 76 71.43 127.94a1.27 1.27 0 01-2.2.99zM220.56 426.8c3 27.78-10.9 46.47-25.31 63.41a1.17 1.17 0 01-2-1c3-13.71 6.93-28.25 8.64-43.11 1.77-15.55-1.58-30.59-10.29-45.59a1.17 1.17 0 00-2.17.54c-1.2 26.49-4 35.92-13 43.87a1.16 1.16 0 01-1.9-1c.06-.56.12-1.08.2-1.59 2.8-23.1-4.2-42.6-21-58.7a64.93 64.93 0 01-8.7-9.5c-11.35-16.5-5.38-39.4 13.82-53.26a1.26 1.26 0 012 .89c1.89 17.88 5.72 34.78 19.78 49.11a1.17 1.17 0 002-.81v-17.57a1.17 1.17 0 011-1.15 1.16 1.16 0 011.24.7c5.92 14.22 15.29 25.75 27.45 35.16 3.4 2.6 7 5.2 10.1 8.1 19.34 18.1 24 46.9 11 67.82a1.16 1.16 0 01-2.15-.65c.26-12.62-1.88-24.18-10.66-35.67z" />
            <path d="M122.73 363.13c3.59-11.58 6-22.3 10.42-33.48 16.7-42.1 49-63.6 94.2-63.4 10.5 0 17.4 5.9 17.7 14.8.3 9.4-6.5 15.7-17.4 15.8-16.1.2-32.1.7-48.2.2-12.1-.4-21.5 4.1-28.8 13.3-3.8 4.8-7.7 9.9-10.4 15.4-6 12.7-16.58 37.74-16.58 37.74a.5.5 0 01-.63.29.49.49 0 01-.31-.65zM85.42 249.37a.53.53 0 011 .18.36.36 0 010 .11c-11.54 48-13 84.68-5.59 99.89a1.26 1.26 0 002.24.08c7.91-14.16 15.63-29.49 25-43.73 8.24-12.52 19.25-22.52 32-30.41a1.26 1.26 0 011.57 2C109 311.05 91.3 351.07 91.05 399.2a1.26 1.26 0 01-2.22.81c-27.94-32.58-36.02-94.57-3.41-150.64zM182 23.39a.86.86 0 01.44-.75c14.92-9 44.26-15.63 57.47-13a1.25 1.25 0 011 1.19q1.5 50.94 2.83 102.12a1.26 1.26 0 01-2.45.43c-11.44-34.84-29.2-65.52-59-89.28a.81.81 0 01-.29-.71z" />
          </svg>
          <p>
            I use my personal{' '}
            <a target="_blank" rel="noreferrer" href="https://github.com/codingChewie">
              Github profile
            </a>{' '}
            for experimental or miscellaneous code for a project down the road or if it doesn't
            pertain to{' '}
            <a target="_blank" rel="noreferrer" href="https://epubknowledge.com/">
              ebooks
            </a>{' '}
            and{' '}
            <a target="_blank" rel="noreferrer" href="https://programmingmonkeys.com/">
              training
            </a>
            .
          </p>
        </Org>
      </PageContainer>
      <PageContainer>
        <PageSubHead>Organizations</PageSubHead>
        <Org org="users/epubknowledge">
          <svg viewBox={'0 0 500 500'}>
            <path d="M272.49 0H0v101h197.64L272.49 0zM350 500l-76-100H116a16 16 0 01-16-16v-83h98l-78-101H16a16 16 0 00-16 16v234a50 50 0 0048 50h127zM500 433.9l-54.48-71.68L332.49 213c-4.66-6.14-4.69-16.22-.07-22.4L475 0H349.68l-6.4 8.65a20.24 20.24 0 00-2.28 2.56L206.79 189.87c-4.63 6.17-8.34 11.27-8.24 11.34s-.09.34-.42.59 3.23 5.47 7.9 11.61l141.82 186.43-.1.07L423.22 500H500z" />
          </svg>
          <p>
            <a target="_blank" rel="noreferrer" href="https://epubknowledge.com/">
              Epub Knowledge
            </a>{' '}
            is an organization I created where I blog, write apps or modules around ePub development
            and best practices. The{' '}
            <a target="_blank" rel="noreferrer" href="https://github.com/epubknowledge">
              Github organization
            </a>{' '}
            is where you can find source code for any app or module I've authored or collected that
            assist in the development process.
          </p>
        </Org>
      </PageContainer>
    </>
  )
}

export default Github
