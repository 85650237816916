import React from 'react'
import styled from 'styled-components'
// import { Link } from 'react-router-dom'

export const BadgeContainer = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(9, 1fr);
  margin-top: ${({ theme }) => theme.spacings.medium};

  ${({ theme }) => theme.breakpoints.md} {
    grid-template-columns: repeat(6, 1fr);
  }
`

export const BadgeTotal = styled.div`
  grid-column: 1/-1;
  color: ${({ theme }) => theme.colors.color2};
  font-size: ${({ theme }) => theme.fontSizes.h4};
`

export const BadgeName = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-size: 75%;
  letter-spacing: 0.02em;
  margin-left: 0.5rem;
  font-variant: small-caps;
`

export const BadgeItem = styled.div`
  // https://codepen.io/sosuke/pen/Pjoqqp
  filter: invert(14%) sepia(12%) saturate(1212%) hue-rotate(181deg) brightness(93%) contrast(89%);

  &:hover {
    filter: opacity(100%);
    transition: ${({ theme }) => theme.animation.ease};
  }
`

export const BadgeImg = styled.img`
  filter: grayscale(100%);
  opacity: 0.4;

  &:hover {
    filter: grayscale(0);
    opacity: 1;
    transition: ${({ theme }) => theme.animation.ease};
  }
`

const TreeBadges = ({ badges }) => {
  return (
    <BadgeContainer>
      <BadgeTotal>
        {badges.length}
        <BadgeName>total badges</BadgeName>
      </BadgeTotal>
      {badges.map(badge => (
        <BadgeItem key={badge.id}>
          <a target="_blank" rel="noopener noreferrer" href={badge.url} title={badge.name}>
            <BadgeImg src={badge.icon_url} alt={badge.name} />
          </a>
        </BadgeItem>
      ))}
    </BadgeContainer>
  )
}

export default TreeBadges
