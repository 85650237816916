import React, { useState, useEffect } from 'react'
import { animateScroll as scroll } from 'react-scroll'

import { FaBars, FaTimes } from 'react-icons/fa'

import { Button } from '../../theme/GlobalStyles'

import {
  NavProvider,
  Nav,
  NavbarContainer,
  MobileIcon,
  NavMenu,
  NavItem,
  NavItemBtn,
  NavLinks,
  NavBtnLink,
} from './NavbarElements'

import Logo from './Logo'

const Navbar = () => {
  const [click, setClick] = useState(false)
  const [button, setButton] = useState(true)

  const handleClick = () => setClick(!click)
  const closeMobileMenu = () => setClick(false)

  const showButton = () => {
    window.innerWidth <= 960 ? setButton(false) : setButton(true)
  }

  useEffect(() => {
    showButton()
  }, [])

  window.addEventListener('resize', showButton)

  const toggleTop = () => scroll.scrollToTop()

  return (
    <NavProvider>
      <Nav>
        <NavbarContainer>
          <Logo click={closeMobileMenu} onCLick={toggleTop} />
          <MobileIcon onClick={handleClick}>{click ? <FaTimes /> : <FaBars />}</MobileIcon>
          <NavMenu onClick={handleClick} click={click}>
            <NavItem>
              <NavLinks to="/" onClick={(closeMobileMenu, toggleTop)}>
                Home
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="/about" onClick={(closeMobileMenu, toggleTop)}>
                About
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="/github" onClick={(closeMobileMenu, toggleTop)}>
                Github
              </NavLinks>
            </NavItem>
            <NavItemBtn>
              {button ? (
                <NavBtnLink to="/contact" onClick={toggleTop}>
                  <Button primary>Contact</Button>
                </NavBtnLink>
              ) : (
                <NavBtnLink to="/contact">
                  <Button onClick={(closeMobileMenu, toggleTop)} fontBig primary>
                    Contact
                  </Button>
                </NavBtnLink>
              )}
            </NavItemBtn>
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </NavProvider>
  )
}

export default Navbar
