import React, { Children } from 'react'

// Components
import Repos from '../Repos'

// Styled
import { OrgContainer, OrgSvg, OrgDiv, OrgText } from './OrgElements'

const Org = props => {
  return (
    <>
      <OrgContainer>
        {Children.map(props.children, (c, k) => {
          if (c.type === 'svg')
            return (
              <OrgSvg key={k} viewBox={c.props.viewBox}>
                {c.props.children}
              </OrgSvg>
            )
        })}
        <OrgDiv>
          {Children.map(props.children, (c, k) => {
            if (c.type === 'p') return <OrgText key={k}>{c.props.children}</OrgText>
          })}
          <Repos repoData={props.org} />
        </OrgDiv>
      </OrgContainer>
    </>
  )
}

export default Org
