import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const LogoWrapper = styled(Link)`
  height: 100%;
`

export const LogoSvg = styled.svg`
  height: 4.25rem;
  margin: 8px 0 0 0;
  fill: ${({ theme }) => theme.colors.color3};

  &:hover {
    fill: ${({ theme }) => theme.colors.color2};
    transition: ${({ theme }) => theme.animation.quick};
  }

  &:visited {
    fill: ${({ theme }) => theme.colors.color2};
  }

  ${({ theme }) => theme.breakpoints.md} {
    height: 3.25rem;
    margin: 18px 0 0 0;
  }
`
