import React from 'react'
import SEO from '../../SEO'

// Components
import TreeHouse from '../../TreeHouse'
import Org from '../../Org'

// React Icons
import { RiSunLine, RiMoonLine } from 'react-icons/ri'
import { MdSchool } from 'react-icons/md'

// Global Style
import { PageHead, PageContainer, PageSubHead } from '../../../theme/GlobalStyles'
import {
  GridContainer,
  GridIcon,
  GridText,
  DegreeLocation,
  DegreeName,
  DegreeYear,
} from './AboutElements'

// Config
import details from '../../../config/details'

console.log(details)
const About = () => {
  return (
    <>
      <SEO title="About" />
      <PageHead>About</PageHead>
      <PageContainer>
        <PageSubHead>Experience</PageSubHead>
        <GridContainer>
          <GridIcon>
            <RiSunLine />
          </GridIcon>
          <GridText>{details.about.day}</GridText>
        </GridContainer>
        <GridContainer>
          <GridIcon>
            <RiMoonLine />
          </GridIcon>
          <GridText>{details.about.night}</GridText>
        </GridContainer>
        <PageSubHead>Education</PageSubHead>
        <GridContainer>
          <GridIcon>
            <MdSchool />
          </GridIcon>
          <GridText>
            <DegreeYear>2010-2012</DegreeYear>
            <DegreeName>Software Development</DegreeName>
            <DegreeLocation>ITT Technical Institute Nashville, Tennessee</DegreeLocation>
          </GridText>
        </GridContainer>
        <GridContainer>
          <GridIcon>
            <MdSchool />
          </GridIcon>
          <GridText>
            <DegreeYear>2008-2010</DegreeYear>
            <DegreeName>Visual Communication</DegreeName>
            <DegreeLocation>ITT Technical Institute Nashville, Tennessee</DegreeLocation>
          </GridText>
        </GridContainer>
        <PageSubHead>Training</PageSubHead>
        <TreeHouse />
        <Org org="orgs/programmingmonkeys">
          <svg viewBox={'0 0 500 500'}>
            <path d="M455 203a30.5 30.5 0 00-21.1 7.5c-.2-.7-.5-1.4-.7-2.1-20.6-58.1-65.1-93.7-102.7-115 11.6-13.6 10-28 10-28s-7.5 3.7-26.1 3.6c14.2-15.3 2.1-38.1 2.1-38.1s-9.6 8.6-29 15.8c4.7-22.9-4.2-43.8-4.2-43.8s1.9 26.8-50.7 50.5c1.2-28.2-6.5-33.9-6.5-33.9S208.3 52.8 184.4 73c-11.1 3.8-22.6 8.8-33.9 15.3h-1.1l.6.3c-36.3 21.2-70.8 58.3-86.7 121.5-.2.6-.3 1.3-.5 1.9-5.2-5.1-11.9-8.6-20.6-8.4-30.6.8-48.4 79.5-29.4 124.7 14.5 34.4 37.8 55.3 59.2 47.8 1 1.9 1.9 3.8 3 5.7 44.3 82.2 146.9 115.9 183.8 115.1 36.3-.8 123.6-27.8 164.4-115.2 1-2.1 2-4.3 2.9-6.5 22.1 4.8 45.7-15.6 60.5-48.3C507 282 487.9 203.8 455 203zm-59 135c-.3.7-.5 1.4-.8 2-8.9 21.3-23.5 37.6-43.6 39.5l-3.4.2S369.1 484 262.6 487c-105.2 3-104.6-60.7-84.5-110.1h-3.8c-8.2.4-28.3.5-41.2-8.4-37.6-26-38.1-106.5-17.3-130.8 20.9-24.3 97.9-6.8 137.5-11.9 39.7-5.1 110.6-27.1 138.8 7.3 7.8 9.5 12.2 24.9 13.4 42.2.2 3 .3 6 .3 9.1.1 18.1-3.2 37.2-9.8 53.6z" />
          </svg>
          <p>
            I created{' '}
            <a target="_blank" rel="noreferrer" href="https://programmingmonkeys.com/">
              Programming Monkeys
            </a>{' '}
            as a way I could blog about code I've learned over the years and where I store source
            code for courses I've taken.
          </p>
        </Org>
      </PageContainer>
    </>
  )
}

export default About
