import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'

// Theme
import { ThemeProvider } from 'styled-components'
import { GlobalStyles } from './theme/GlobalStyles'
import Theme from './theme/theme'

ReactDOM.render(
  <ThemeProvider theme={Theme}>
    <GlobalStyles />
    <App />
  </ThemeProvider>,
  document.getElementById('root'),
)
