import React, { useEffect, useState } from 'react'
import axios from 'axios'

// Components
import Loader from '../Loader'

// Styled
import {
  RepoContainer,
  Repo,
  RepoLink,
  RepoIcon,
  RepoTitle,
  RepoDesc,
  RepoLanguage,
} from './ReposElements'

const Repos = ({ repoData }) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  const fetchRepos = async () => {
    try {
      const { data } = await axios.get(
        `https://api.github.com/${repoData}/repos?page=1&per_page=1000`,
      )

      setData(data)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setData('error')
    }
  }

  // eslint-disable-next-line
  useEffect(() => fetchRepos(), [])

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <RepoContainer>
          {data.map(repo => (
            <RepoLink key={repo.id} href={repo.html_url} target="_blank" rel="noreferrer">
              <Repo>
                <RepoIcon />
                <RepoTitle>{repo.name.replace(/-/g, ' ').toLowerCase()}</RepoTitle>
                {repo.description && <RepoDesc>{repo.description}</RepoDesc>}
                {repo.language && <RepoLanguage>{repo.language}</RepoLanguage>}
              </Repo>
            </RepoLink>
          ))}
        </RepoContainer>
      )}
    </>
  )
}

export default Repos
