import styled from 'styled-components'

export const FooterContainer = styled.div`
  display: flex;
  height: ${({ theme }) => theme.spacings.large};
  color: ${({ theme }) => theme.colors.color4};
  font-size: ${({ theme }) => theme.fontSizes.small};
  letter-spacing: 0.05rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  bottom: 0;
`

export const FooterWrapper = styled.footer`
  flex-shrink: 0;
  padding: 20px;
`
