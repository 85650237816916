import styled from 'styled-components'

export const GridContainer = styled.div`
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: 4rem 3fr;
  margin-bottom: ${({ theme }) => theme.spacings.medium};

  ${({ theme }) => theme.breakpoints.md} {
    grid-template-columns: 2rem 3fr;
    grid-gap: 2rem;
  }
`
export const GridIcon = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.h3};
  color: ${({ theme }) => theme.colors.color3};
`

export const GridText = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.h5};
`

export const DegreeLocation = styled.div`
  font-size: smaller;
`

export const DegreeName = styled.div`
  font-weight: 200;
`

export const DegreeYear = styled.div`
  font-weight: bold;
  letter-spacing: 0.03em;
`
